<template>
  <y-layout-body-menu>
  <div>
    <el-card style="min-height: 45rem">
      <el-form>
        <el-form-item label="启用自定义菜单">
          <el-switch @change="submitTabbar" v-model="tabbar.enable"></el-switch>
          <span class="y-desc" style="color: red">菜单项须添加5个才可启用</span>
        </el-form-item>
      </el-form>
      <el-table :data="list" border>
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column prop="title" label="名称"></el-table-column>
        <el-table-column prop="icon_default" label="默认图标">
          <template #default="s">
            <img class="flex-def" :src="s.row.icon_default | tomedia" style="height: 2rem;width: 2rem;border-radius: .2rem" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="icon_active" label="激活图标">
          <template #default="s">
            <img class="flex-def" :src="s.row.icon_active | tomedia" style="height: 2rem;width: 2rem;border-radius: .2rem" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="link" label="菜单链接"></el-table-column>
        <el-table-column label="操作" align="center">
          <template #header>
            <el-button @click="editFormBox=true" type="primary">添加菜单项</el-button>
          </template>
          <template #default="s">
            <el-button @click="editItem(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog title="编辑菜单项" width="30rem" :visible.sync="editFormBox" @close="editFormClose">
        <el-form label-width="5rem" style="padding: 0 2rem">
          <el-form-item label="排序">
            <el-input-number v-model="form.sort"></el-input-number>
          </el-form-item>
          <el-form-item label="名称">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="默认图标">
            <y_upload_img v-model="form.icon_default"></y_upload_img>
          </el-form-item>
          <el-form-item label="激活图标">
            <y_upload_img v-model="form.icon_active"></y_upload_img>
          </el-form-item>
          <el-form-item label="菜单链接">
            <y_choose_link :use-custom="false" v-model="form.link"></y_choose_link>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editFormBox = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
  </y-layout-body-menu>
</template>

<script>
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
import y_choose_link from "@/components/shop/y_choose_link";
import YLayoutBodyMenu from "@/components/layouts/YLayoutBodyMenu";
export default {
  name: "banner",
  components:{
    YLayoutBodyMenu,
    y_upload_img,
    y_choose_link
  },
  data() {
    return {
      tabbar:{
        enable:false
      },
      list:[],
      form:{
        sort:0,
        title:"",
        icon_default:"",
        icon_active:"",
        link:""
      },
      editFormBox:false,
    }
  },
  mounted() {
    this.load();
    this.loadTabbar();
  },
  methods: {
    loadTabbar(){
      this.$u.api.system.tabbar().then(res=>{
        this.tabbar = res;
      })
    },
    submitTabbar(){
      this.$u.api.system.tabbarEdit(this.tabbar).then(()=>{
        this.$message.success("操作成功");
        this.loadTabbar();
      }).catch(()=>this.loadTabbar());
    },
    del(item){
      this.$u.api.system.tabbarItemDel({id:item.id}).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
    editItem(item){
      this.form = {...item};
      this.editFormBox = true;
    },
    editFormClose(){
      this.form = {
        sort:0,
        title:"",
        icon_default:"",
        icon_active:"",
        link:""
      }
      this.editFormBox = false;
    },
    load() {
      this.$u.api.system.tabbarItem().then(res=>{
        this.list = res;
      })
    },
    edit(){
      this.$u.api.system.tabbarItemEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>